import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { FaRegUser, FaRegUserCircle } from "react-icons/fa";
import Context from "../store/Context";
import { toast } from "react-toastify";

function UserProfile() {
  const connectionUrl = process.env.REACT_APP_BACKEND_URL;

  const ctx = useContext(Context);
  console.log(ctx.admincurrentUserData);
  // const currentUserData = ctx.adminCurrentUserData;
  const [currentUserData, setCurrentUserData] = useState({});
  useEffect(() => {
    setCurrentUserData(ctx.adminCurrentUserData);
  }, [ctx.adminCurrentUserData]);
  const user = JSON.parse(localStorage.getItem("token"));

  const deleteUserHandler = async (id) => {
    try {
      const response = await axios.post(`${connectionUrl}/admin/deleteUser`, {
        delId: id,
        token: user.access_token,
        domain: user.domain,
      });

      toast.success("user deleted successfully...");
    } catch (err) {
      console.log(err);
    }
  };
  const removePaymentHandlingPermission = async (id) => {
    setCurrentUserData((prev) => ({ ...prev, paymentAdmin: false }));
    try {
      const response = await axios.post(
        `${connectionUrl}/admin/removePaymentHandlingPermission`,
        {
          paymentAdmin: id,
          token: user.access_token,
          domain: user.domain,
        }
      );

      toast.success("payment handling permission removed ...");
    } catch (err) {
      setCurrentUserData((prev) => ({ ...prev, paymentAdmin: true }));
      if (err.response && err.response.data.msg) {
        toast.error(err.response.data.msg);
      }
      console.log(err);
    }
  };
  const givePaymentPermission = async (id) => {
    setCurrentUserData((prev) => ({ ...prev, paymentAdmin: true }));
    try {
      const response = await axios.post(
        `${connectionUrl}/admin/givePaymentHandlingPermission`,
        {
          paymentAdmin: id,
          token: user.access_token,
          domain: user.domain,
        }
      );

      toast.success("payment handling permission given ...");
    } catch (err) {
      setCurrentUserData((prev) => ({ ...prev, paymentAdmin: false }));
      if (err.response && err.response.data.msg) {
        toast.error(err.response.data.msg);
      }
      console.log(err);
    }
  };
  const assignAdminHandler = async (id) => {
    setCurrentUserData((prev) => ({ ...prev, isAdmin: true }));
    try {
      const response = await axios.post(
        `${connectionUrl}/admin/assignAsAdmin`,
        {
          adminId: id,
          token: user.access_token,
          domain: user.domain,
        }
      );

      toast.success("user assigned as Admin ...");
    } catch (err) {
      setCurrentUserData((prev) => ({ ...prev, isAdmin: false }));
      if (err.response && err.response.data.msg) {
        toast.error(err.response.data.msg);
      }
      console.log(err);
    }
  };
  const removeAdminHandler = async (id) => {
    setCurrentUserData((prev) => ({ ...prev, isAdmin: false }));
    try {
      const response = await axios.post(
        `${connectionUrl}/admin/removeAsAdmin`,
        {
          adminId: id,
          token: user.access_token,
          domain: user.domain,
        }
      );

      toast.success("admin access removed ...");
    } catch (err) {
      setCurrentUserData((prev) => ({ ...prev, isAdmin: true }));
      if (err.response && err.response.data.msg) {
        toast.error(err.response.data.msg);
      }
      console.log(err);
    }
  };
  return (
    <div className="relative rounded-lg shadow-md shadow-gray-700  w-[100%]  min-[689px]:max-w-[40%]  min-h-[250px] h-[40vh]  min-[689px]:m-2 my-2  bg-white text-black">
      <p className="relative rounded-t-lg py-2 font-bold text-2xl md:text-3xl   text-center   bg-[#2980b9] text-white">
        User Profile
      </p>{" "}
      {/* {user?.isAdmin && (
        <div
          className="absolute   bg-red-500 hover:bg-red-700 text-white px-1 cursor-pointer z-10"
          onClick={() => deleteUserHandler(currentUserData.id)}
        >
          remove
        </div>
      )} */}
      <div className=" overflow-y-scroll h-[calc(40vh-50px)] min-h-[200px] ">
        {" "}
        <div className="p-2 pt-4 flex border-b-2">
          {currentUserData?.profilePic ? (
            <img
              src={currentUserData?.profilePic}
              alt="profile"
              className="w-[80px] h-[80px] m-2 border-2"
            ></img>
          ) : (
            <FaRegUser className="w-[80px] h-[80px] m-2 border-2 border-black p-2"></FaRegUser>
          )}
          <div className="py-2 flex flex-col">
            <p>
              {currentUserData?.firstName + " " + currentUserData?.lastName}
            </p>
            {currentUserData?.designation && (
              <p className="text-sm"> {`(${currentUserData?.designation})`}</p>
            )}
            <div className="flex">
              <p className="font-bold ">UserId :</p>{" "}
              <p className=" px-2"> {currentUserData?.id}</p>
            </div>
          </div>{" "}
        </div>
        <div className="px-4 py-2 border-b-2 flex">
          <p className="font-bold text-nowrap text-[.75rem] lg:text-[.9rem]">
            Email :
          </p>

          <p className="text-[.75rem] lg:text-[.9rem] justify-start px-2 text-wrap">
            {currentUserData?.email}
          </p>
        </div>
        <div className="px-4 py-2 border-b-2 flex">
          <p className="font-bold text-nowrap text-[.75rem] lg:text-[.9rem]">
            Phone :
          </p>

          <p className="text-[.75rem] lg:text-[.9rem] justify-start px-2">
            {currentUserData?.mobile}
          </p>
        </div>
        <div className="px-4 py-2 border-b-2 w-full">
          <p className="font-bold text-nowrap text-[.75rem] lg:text-[.9rem]">
            Permissions :
          </p>
          <div className="flex flex-wrap ">
            {currentUserData.supervisor && !currentUserData.isAdmin && (
              <p className="border-[2px] text-[.75rem] lg:text-[.9rem] font-semibold border-blue-600 px-2 rounded-md flex items-center mx-1">
                SuperVisor{" "}
              </p>
            )}{" "}
            {currentUserData.isAdmin && (
              <p className="border-[2px] text-[.75rem] lg:text-[.9rem] font-semibold border-blue-600 px-2 rounded-md flex items-center mx-1">
                Admin{" "}
              </p>
            )}
            {!currentUserData.supervisor && !currentUserData.isAdmin && (
              <p className="border-[2px] text-[.75rem] lg:text-[.9rem] font-semibold border-blue-600 px-2 rounded-md flex items-center mx-1">
                User Only
              </p>
            )}
            {/* {console.log(ctx.admincurrentUserData)} */}
            {currentUserData.paymentAdmin && (
              <p className="border-[2px] text-[.75rem] lg:text-[.9rem] font-semibold border-blue-600 px-2 rounded-md flex items-center mx-1">
                Payment Handler
              </p>
            )}
          </div>
          <div className="flex my-2">
            {currentUserData?.isAdmin ? (
              <p
                className="border-2 bg-red-600 px-2 flex flex-nowrap rounded-md text-[.75rem] lg:text-[.9rem] font-bold text-white cursor-pointer hover:bg-red-700 w-fit  whitespace-nowrap"
                onClick={() => {
                  removeAdminHandler(currentUserData.id);
                }}
              >
                Remove Admin
              </p>
            ) : (
              <p
                className="border-2 bg-green-600 px-2  flex flex-nowrap rounded-md text-[.75rem] lg:text-[.9rem] font-bold text-white cursor-pointer hover:bg-green-700  w-fit  whitespace-nowrap"
                onClick={() => {
                  assignAdminHandler(currentUserData.id);
                }}
              >
                Assign Admin
              </p>
            )}
            {!currentUserData?.paymentAdmin ? (
              <p
                className="border-2 bg-green-600 px-2  flex flex-nowrap rounded-md text-[.75rem] lg:text-[.9rem] font-bold text-white cursor-pointer hover:bg-green-700  w-fit  whitespace-nowrap"
                onClick={() => {
                  givePaymentPermission(currentUserData.id);
                }}
              >
                Give Payment Handling Permission
              </p>
            ) : (
              <p
                className="border-2 bg-red-600 px-2 flex flex-nowrap rounded-md text-[.75rem] lg:text-[.9rem] font-bold text-white cursor-pointer hover:bg-red-700 w-fit  whitespace-nowrap"
                onClick={() => {
                  removePaymentHandlingPermission(currentUserData.id);
                }}
              >
                Remove Payment handling Permission
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
